import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

export default function Template({ data, location }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <>
      <Seo title={frontmatter.title} path={location.pathname} />
      <Layout>
        <div className="wrapper m" style={{ marginTop: '100px' }}>
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
